import axios from "axios"
import { Geometry, LineString, Point } from 'geojson';
import { TValidation } from "./validationsController";

const BASE_URL = `${process.env.REACT_APP_API_SERVER_URL}/v1/edit`

export type TUploadedFeature = {
    layer_name: string;
    id: string;
    feature: {
        geometry: any;
        properties: any
    }
    validations?: TValidation[]
}
export type TProposeFeatureResponseBody = {
    geometry: Geometry
    featureId: string
    featureLayer: string
    nearestAddressPoint: TUploadedFeature
    changedProperties?: {
        [key: string]: any
    }
    autoPopulatedProperties?: {
        [key: string]: any
    }
    allNearestAddressPoints: TUploadedFeature[]
    propertyToFocus?: string
};

export type TFishboneProposeResponse = {
    featureProposal: TProposeFeatureResponseBody;
    fishboneInfo: TFishboneInfo;
    fishboneProposalInfo: TFishboneProposalInfo;
};

export type TFishboneInfo = {
    fishboneFeature: TUploadedFeature
    leftPoints: TFishbonePoint[];
    leftLines: LineString[];
    rightPoints: TFishbonePoint[];
    rightLines: LineString[];
};

export type TFishboneProposalInfo = {
    proposalPoints: TFishbonePoint[];
    proposalLines: LineString[];
};

export type TFeatureProposalResponse = {
    nearestAddressPoint: TUploadedFeature;
    changedProperties: { [key: string]: any };
    autoPopulatedProperties: { [key: string]: any };
    propertyToFocus?: string;
    allNearestAddressPoints?: TUploadedFeature[];
};

export type TFishbonePoint = {
    houseNumber: number;
    side: string;
    point: Point;
    correlatedToAp: boolean;
};


export const proposeFeature = async (
    { accessToken, organizationId, uploadId, geometry, featureId, previousHouseNumber }:
        { accessToken: any, organizationId: any, uploadId: any, geometry: any, featureId: string, previousHouseNumber?: number }) => {
    return await axios.post<TFishboneProposeResponse>(
        `${BASE_URL}/fishbone/propose`,
        {
            UploadId: uploadId,
            Geometry: geometry,
            FeatureId: featureId,
            previousHouseNumber: previousHouseNumber
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}

export const saveFeature = async (
    { accessToken, organizationId, uploadId, layerName, feature }:
        { accessToken: any, organizationId: any, uploadId: any, layerName: any, feature: any }) => {
    return await axios.post<any>(
        `${BASE_URL}/save`,
        {
            UploadId: uploadId,
            LayerName: layerName,
            Feature: feature,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}

type TUpdatedFeatureResponse = {
    featureId: string
}
export const updateFeature = async (
    { accessToken, organizationId, uploadId, featureId, feature }:
        { accessToken: string, organizationId: string, uploadId: string, featureId: string, feature: any }) => {
    return await axios.post<TUpdatedFeatureResponse>(
        `${BASE_URL}/update`,
        {
            UploadId: uploadId,
            FeatureId: featureId,
            Feature: feature,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}

export const deleteFeature = async (
    { accessToken, organizationId, uploadId, featureId }:
        { accessToken: string, organizationId: string, uploadId: string, featureId: string }
) => {
    return await axios.post<any>(
        `${BASE_URL}/delete`,
        {
            UploadId: uploadId,
            FeatureId: featureId,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}


export const fishboneFeature = async ({
    accessToken,
    organizationId,
    uploadId,
    featureId,
}: {
    accessToken: string,
    organizationId: string,
    uploadId: string,
    featureId: string,
}) => {
    console.log(`fishboneFeature: ${uploadId}, ${featureId}`)
    return await axios.post<TFishboneInfo>(
        `${BASE_URL}/fishbone`,
        {
            UploadId: uploadId,
            FeatureId: featureId,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}

/**
 * Right now it regenerates the entire fishbone
 * Maybe be able to improve the speed by making a new endpoint that accepts
 * The Fishbone data already ??
 * But you do still have to know the RCL in or
 */
export const includePointToRoadCenterLineFishbone = async ({
    accessToken,
    organizationId,
    uploadId,
    point,
    proposedFeatureId,
    rclFeatureId
}: {
    accessToken: string,
    organizationId: string,
    uploadId: string,
    point: Point,
    proposedFeatureId: string,
    rclFeatureId: string,
}) => {
    return await axios.post<TFishboneProposeResponse>(
        `${BASE_URL}/fishbone/include-point-road-center-line`,
        {
            UploadId: uploadId,
            RCLFeatureId: rclFeatureId,
            ProposedFeatureId: proposedFeatureId,
            Point: point,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            params: {
                organizationId
            }
        },
    )
}

