import ReactMap, { Layer, MapRef, Source, ViewState } from 'react-map-gl'
import Shell from '../layout/Shell'
import {
    Alert,
    Card,
    IconButton,
    MenuItem,
    Select,
    Snackbar
} from '@mui/material'
import mapboxgl from 'mapbox-gl'
import { useParams } from 'react-router-dom'
import { useAppContext } from '../App'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { getOrganizations, TOrganizationResult } from '../hooks/useOrganization'
import { useMutation, useQuery } from 'react-query'
import { DrawControl } from '../components/map/DrawControl'
import { fishboneFeature, TFishboneInfo, TFishboneProposalInfo, TFishboneProposeResponse, } from '../api/editController'
import useQueryParams from "../hooks/useQueryParams";
import DashboardIcon from '@mui/icons-material/Dashboard';
import LayersIcon from '@mui/icons-material/Layers';
import { getAllLayerFeaturePropertyOrders, getFeatureById } from '../api/featureController'
import bbox from '@turf/bbox'
import { getTemplates } from '../api/modelsController'
import SidePanelTabs, { TabItem } from '../components/map/SidePanelTabs'
import SearchIcon from '@mui/icons-material/Search';
import { SearchTab } from '../components/map/tabs/SearchFeatureTab'
import { SelectLayerTab, TLayerSelection } from '../components/map/tabs/SelectLayerTab'

import { Feature, LineString, GeoJsonProperties, Geometry, Point, FeatureCollection } from 'geojson';
import distance from '@turf/distance'
import { booleanIntersects, booleanPointOnLine, pointToLineDistance } from '@turf/turf'
import { v4 as uuid } from 'uuid';
import FeatureManager from '../components/FeatureManager'
import { Layers } from "../components/map/Layers";

export type TClickedFeature = {
    sourceLayer?: string
    type: string
    geometry?: any
    properties?: { [key: string]: string }
}

export type TSystemNameDictionary = {
    [key: string]: {
        layerName: string;
        fields: {
            [key: string]: string;
        };
    };
}

export type TProposalInfo = {
    featureId: string
    layerName: string
    geometry: Geometry
    properties: { [key: string]: any }[]
    changedProperties?: { [key: string]: any }
    propertyToFocus?: string
}

export type THoverAdditions = {
    prefix: string
}

const SELECT_TAB_ID = 0;
const SEARCH_TAB_ID = 1;
const FEATURE_MANAGER_TAB = 2;

const possibleMapStyles = [
    {
        displayName: "Light",
        mapStyle: "mapbox://styles/mapbox/light-v11"
    },
    {
        displayName: "Dark",
        mapStyle: "mapbox://styles/mapbox/dark-v11"
    },
    {
        displayName: "Satellite",
        mapStyle: "mapbox://styles/mapbox/satellite-streets-v12"
    },
    {
        displayName: "Outdoors",
        mapStyle: "mapbox://styles/mapbox/outdoors-v12"
    },
    // TODO: Enable this one once we're upgraded properly to mapbox-gl v3
    // {
    //     displayName: "Standard",
    //     mapStyle: "mapbox://styles/mapbox/standard"
    // },
    {
        displayName: "Navigation",
        mapStyle: "mapbox://styles/mapbox/navigation-day-v1"
    },
    {
        displayName: "Unlabeled",
        mapStyle: "mapbox://styles/matt-dickinson-911geo-com/cm3fcddke001d01so4g80fsyo",
    }
]


const MapPage: React.FC = () => {
    const mapRef = useRef<MapRef>(null)
    const { fileUploadId } = useParams<{ fileUploadId: string }>()
    const qp = useQueryParams();
    const currentOrganizationId = qp.get("organizationId")
    const layerName = qp.get("layerName")
    const featureId = qp.get("featureId")
    const { accessToken } = useAppContext()
    const [mapStyle, setMapStyle] = useState<string>("mapbox://styles/mapbox/light-v11")
    const [selectedFeature, setSelectedFeature] = useState<{ layerName: string, feature: Feature }>()
    const [firstSymbolLayerId, setFirstSymbolLayerId] = useState<string | undefined>()
    const [layerSelection, setLayerSelection] = useState<TLayerSelection[]>([])
    const [mapRefLoaded, setMapRefLoaded] = useState(false)
    const [proposalInfo, setProposalInfo] = useState<TProposalInfo | undefined>()
    const [viewState, setViewState] = useState<ViewState>({
        // The Woodlands in Gladstone, MO
        latitude: 39.205867,
        longitude: -94.544264,
        zoom: 3.5,
        bearing: 0,
        pitch: 0,
        padding: { bottom: 0, top: 0, right: 0, left: 0 }
    })
    const [viewStateSet, setViewStateSet] = useState(false)
    const [activeSidePanelTab, setActiveSidePanelTab] = useState(0)
    const [featureSetOnIntialLoad, setFeatureSetOnIntialLoad] = useState(false)
    const [fishboneFeatureCollection, setFishboneFeatureCollection] = useState<FeatureCollection<Geometry, GeoJsonProperties>>();
    const [fishboneFeatureCollectionHouseLine, setFishboneFeatureCollectionHouseLine] = useState<FeatureCollection<Geometry, GeoJsonProperties>>();
    const [proposeFishboneFeatureCollection, setProposedFishboneFeatureCollection] = useState<FeatureCollection<Geometry, GeoJsonProperties>>();

    const [loadedMapSources, setLoadedMapSources] = useState<string[]>([])
    const selectedFeaturesToClear = useRef<{ layerName: string, feature: Feature }[]>([])

    const [hoverAdditions, setHoverAdditions] = useState<THoverAdditions>()
    const [drawRefFeature, setDrawRefFeature] = useState<{ geometry: Geometry, featureId: string } | null>(null)
    useEffect(() => {
        const selectedFeatures = selectedFeature ? [selectedFeature] : []
        if (!(mapRefLoaded && selectedFeature)) return
        selectedFeaturesToClear.current?.forEach(({ layerName, feature }) => {
            if (!loadedMapSources.includes(layerName)) return
            mapRef.current?.setFeatureState({
                source: layerName,
                sourceLayer: layerName,
                id: feature.id,
            }, {
                click: false,
            })
        })
        selectedFeatures.forEach(({ layerName, feature }) => {
            if (!loadedMapSources.includes(layerName)) return
            mapRef.current?.setFeatureState({
                source: layerName,
                sourceLayer: layerName,
                id: feature.id,
            }, {
                click: true,
            })
        })
        selectedFeaturesToClear.current = selectedFeatures
    }, [loadedMapSources, mapRefLoaded, selectedFeature])
    const onSourceData = (e: mapboxgl.MapSourceDataEvent) => {
        e.isSourceLoaded && setLoadedMapSources([...loadedMapSources, e.sourceId])
    }
    const geoJSONPoint: (point: mapboxgl.LngLat) => { type: 'Point', coordinates: number[] } = (point) => ({ type: 'Point', coordinates: [point.lng, point.lat] })
    const getDistance = (point: Point, geometry: Geometry) => {
        if (geometry.type === 'Point') {
            return distance(point, geometry)
        }
        if (geometry.type === 'MultiPoint') {
            return Math.min(...geometry.coordinates.map((coord) => distance(point, { type: 'Point', coordinates: coord })))
        }
        if (geometry.type === 'LineString') {
            return pointToLineDistance(point, geometry)
        }
        if (geometry.type === 'MultiLineString') {
            const nearestGeometryCoordinates = geometry.coordinates.reduce(
                (previousValue, currentValue) => {
                    const previousDistance = pointToLineDistance(point, { type: 'LineString', coordinates: previousValue })
                    const currentDistance = pointToLineDistance(point, { type: 'LineString', coordinates: currentValue })
                    return previousDistance < currentDistance ? previousValue : currentValue
                }
            )
            return pointToLineDistance(point, { type: 'LineString', coordinates: nearestGeometryCoordinates })
        }
        if (geometry.type === 'Polygon' || geometry.type === 'MultiPolygon') {
            return booleanIntersects(point, geometry) ? 0 : Infinity // user must click within polygon, still need to handle overlapping polygons, first one will be selected, eventually we just need ability to select more than one thing
        }
        return Infinity
    }
    const bufferPoint = (point: mapboxgl.Point, pixelBuffer = 16) => [[point.x - pixelBuffer, point.y - pixelBuffer], [point.x + pixelBuffer, point.y + pixelBuffer]] as [mapboxgl.PointLike, mapboxgl.PointLike]

    const makeFeatureMoveable = useCallback((featureIds: string[]) => {
        if (drawControlRef.current) {
            const features = mapRef?.current?.queryRenderedFeatures(undefined, {
                layers: layerSelection.filter(ls => ls.checked).map(ls => `${ls.layerInfo.name}-1`),
            });

            if (features == null || features.length < 1) {
                return;
            }
            const matchingFeatures = features?.filter(f => (f.id && featureIds.includes(f.id as string)))
            if (matchingFeatures == null || matchingFeatures.length < 1) {
                return
            }
            let matchedFeatureIds: string[] = []
            matchingFeatures.forEach(f => {
                drawControlRef.current?.add(f);
                if (f.id) {
                    matchedFeatureIds.push(f.id as string)
                }
            })

            drawControlRef.current?.changeMode('simple_select', {
                featureIds: matchedFeatureIds
            })
        }
    }, [layerSelection])

    useEffect(() => {
        if (proposeFishboneFeatureCollection) {
            // // const features = map.querySourceFeatures(sourceId, {
            // //     sourceLayer: 'your-source-layer',  // Optional: specify a source layer
            // //     filter: ['==', 'property_name', 'property_value'], // Optional: apply filters
            // // });
            // const x = (drawRefFeature?.geometry as Point).coordinates[0]
            // const y = (drawRefFeature?.geometry as Point).coordinates[1]

            // const features = mapRef.current?.queryRenderedFeatures([x, y], {
            //     layers: ['fishbone-propose-source']  // Optionally specify the layers you want to check
            // });

            // if (!features || features.length > 0) {
            //     return
            // }
            // drawControlRef.current?.add(features[0]);

            // drawControlRef.current?.changeMode('simple_select', {
            //     featureIds: [features[0].id as string]
            // })
        }
    }, [drawRefFeature, proposeFishboneFeatureCollection])

    const onClick = useCallback((e: mapboxgl.MapLayerMouseEvent) => {
        let allFeatures = e.target.queryRenderedFeatures(bufferPoint(e.point))
        if (allFeatures && allFeatures.length > 0 && drawRefFeature && allFeatures.some(feature => feature.layer.id.includes('fishbone'))) return;

        const features = e.target.queryRenderedFeatures(bufferPoint(e.point), { layers: layerSelection.filter(ls => ls.checked).map(ls => `${ls.layerInfo.name}-1`) })
        if (features.length === 0) return

        const nearestFeature = features.reduce((previousValue, currentValue) => {
            const previousValueDistance = getDistance(geoJSONPoint(e.lngLat), previousValue.geometry)
            const currentValueDistance = getDistance(geoJSONPoint(e.lngLat), currentValue.geometry)
            if ((previousValue.geometry.type === 'Point' || previousValue.geometry.type === 'MultiPoint' || previousValue.geometry.type === 'LineString' || previousValue.geometry.type === 'MultiLineString') && (currentValue.geometry.type === 'Polygon' || currentValue.geometry.type === 'MultiPolygon')) {
                return previousValue
            }
            return previousValueDistance < currentValueDistance ? previousValue : currentValue
        })
        setSelectedFeature({ layerName: nearestFeature.source, feature: nearestFeature })
    }, [drawRefFeature, layerSelection])
    const { data: organizationResult } = useQuery<TOrganizationResult | Error>('get-organizations', () =>
        getOrganizations(accessToken!)
    )
    // gets the data models so we can map the systemLayerNames to the source layer names
    const [sourceDataAPLayerName, setSourceDataAPLayerName] = useState('');
    const [sourceDataRCLLayerName, setSourceDataRCLLayerName] = useState('');
    const [systemNameDictionary, setSystemNameDictionary] = useState<TSystemNameDictionary>();

    const { data: getTemplatesRespBody } = useQuery('get-templates', () => {
        if (!accessToken) {
            return
        }
        return getTemplates(accessToken)
    });

    // snackbar stuff
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const resetSnackbar = () => {
        setOpenSnackbar(false);
        setTimeout(() => {
            setSnackbarAlertSeverity('info')
            setSnackbarAlertContent('')
        }, 0)
    }

    const openSuccessSnackbar = (msg: string) => {
        setSnackbarAlertSeverity('success')
        setSnackbarAlertContent(msg)
        setOpenSnackbar(true)
    }

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        resetSnackbar()
    };

    type TAlertSeverity = 'success' | 'info' | 'warning' | 'error';
    const [snackbarAlertSeverity, setSnackbarAlertSeverity] = useState<TAlertSeverity>('info');
    const [snackbarAlertContent, setSnackbarAlertContent] = useState<ReactNode>('');

    const [propsByLayerNameObj, setPropsByLayerNameObj] = useState<any>()

    // this is the contextual organization the user belongs to or undefined
    let templateStringMap = new Map<string, string>();
    const _organization = organizationResult && !(organizationResult instanceof Error) && organizationResult.belongs
        ? organizationResult.organizations.find((org) => org.id === currentOrganizationId) : undefined
    const fuModelLayers = _organization?.fuModels?.find((fm) => fm.source === fileUploadId)?.fuModelLayers ?? [];
    for (const fuModelLayer of fuModelLayers) {
        const { source, templateString } = fuModelLayer;
        // set template string map values
        if (typeof source === 'string' && typeof templateString === 'string') {
            templateStringMap.set(source, templateString);
        }
    }

    // 18ish is a good zome for a feature, 10ish is a good zoom for a layer
    const focusFeatureByGeometry = useCallback((geometry: Geometry, zoom: number = 10) => {
        let bboxResult = bbox(geometry)
        let xMin = bboxResult[0]
        let xMax = bboxResult[2]
        let yMin = bboxResult[1]
        let yMax = bboxResult[3]
        focusFeatureByXYCoordinates(xMin, xMax, yMin, yMax, zoom)
    }, [])

    const focusFeatureByXYCoordinates = (xMin: number, xMax: number, yMin: number, yMax: number, zoom: number = 10) => {
        const bounds = new mapboxgl.LngLatBounds(
            new mapboxgl.LngLat(xMin, yMin),
            new mapboxgl.LngLat(xMax, yMax),
        )

        try {
            mapRef?.current?.fitBounds(bounds, { zoom: zoom, duration: 2000 })
        } catch (e) {
            console.log(e)
        }
    }

    // map view state effect
    useEffect(() => {
        if (typeof accessToken === 'undefined') {
            return
        }

        if (!organizationResult) {
            return
        }

        if (organizationResult instanceof Error || !organizationResult.belongs) {
            return
        }

        const currentOrganization = organizationResult.organizations.find((org) => org.id === currentOrganizationId)

        async function fetchFeatureById(accessToken: string, currentOrganizationId: string, fileUploadId: string, featureId: string) {
            let response = await getFeatureById(accessToken, currentOrganizationId!, fileUploadId, featureId);

            const feature = { ...response.data?.feature, id: response.data?.id }
            setSelectedFeature({ layerName: response.data?.layerName, feature })
            focusFeatureByGeometry(response.data?.feature?.geometry, 18)
        }

        if (!currentOrganization) {
            return
        }
        if (mapRefLoaded && !viewStateSet) {
            setViewStateSet(true)
            const fuId = currentOrganization.fileUploads.find((f: any) => f.uploadId === fileUploadId)?.uploadId

            const orgLayers = currentOrganization.fileUploads.find((f: any) => f.uploadId === fuId)?.layers ?? []

            let layerOrder = 1
            setLayerSelection(orgLayers.filter(ol => ol.type !== 'None').map(ol => {
                const isAddressPoint = ol.name === sourceDataAPLayerName
                const isRoadCenterLineLayer = ol.name === sourceDataRCLLayerName;

                return new TLayerSelection(
                    ol,
                    isAddressPoint || isRoadCenterLineLayer, //harcode for addresspoints to be selected
                    isAddressPoint ? { r: 250, g: 0, b: 0 } : { r: 0, g: 0, b: 255 }, //harcode for addresspoints to be selected,
                    isRoadCenterLineLayer ? 0 : isAddressPoint ? 1 : layerOrder++ // roads should be first, then address points, then the rest
                )
            }))
            if (featureId && mapRef.current) {
                fetchFeatureById(accessToken, currentOrganizationId!, fuId!, featureId)
            } else {
                const layerWithMostFeatures = orgLayers.reduce((prev: any, curr: any) => prev?.expectedFeatureCount > curr?.expectedFeatureCount ? prev : curr)
                if (layerWithMostFeatures?.extent?.xMin && layerWithMostFeatures?.extent?.yMin && layerWithMostFeatures?.extent?.xMax && layerWithMostFeatures?.extent?.yMax && mapRef.current) {
                    focusFeatureByXYCoordinates(layerWithMostFeatures?.extent?.xMin, layerWithMostFeatures?.extent?.xMax, layerWithMostFeatures?.extent?.yMin, layerWithMostFeatures?.extent?.yMax)
                }
                setViewState({ ...viewState, ...currentOrganization.initialViewport })
            }
        }
    }, [accessToken, organizationResult, currentOrganizationId, mapRefLoaded, viewStateSet, viewState, fileUploadId, featureId, sourceDataAPLayerName, sourceDataRCLLayerName, focusFeatureByGeometry])

    // layer selection effect
    useEffect(() => {

        if (typeof accessToken === 'undefined' || typeof organizationResult === 'undefined') {
            return
        }

        if (!organizationResult) {
            return
        }

        if (organizationResult instanceof Error || !organizationResult.belongs) {
            return
        }

        const currentOrganization = organizationResult.organizations.find((org) => org.id === currentOrganizationId)

        if (!currentOrganization) {
            return
        }
        if (!featureSetOnIntialLoad) {
            const fuId = currentOrganization.fileUploads.find(f => f.uploadId === fileUploadId)?.uploadId
            if (!fuId) {
                setFeatureSetOnIntialLoad(true)
                return
            }
            if (layerName && layerSelection.length > 0) {
                setLayerSelection(layerSelection.map(ls => {
                    return layerName === ls.layerInfo.name ? new TLayerSelection(ls.layerInfo, true, ls.rgbColor, ls.selectionOrder) : ls
                }))
                setFeatureSetOnIntialLoad(true)
            }

        }
    }, [accessToken, organizationResult, currentOrganizationId, fileUploadId, layerName, layerSelection, featureSetOnIntialLoad])

    // takes care of setting the source AP and RCL layer names for usage on the map
    // it does this by correlating the fuModelLayers with the templates and then using
    // the systemLayerName to set the source layer name
    useEffect(() => {
        let newSystemNameDictionary: TSystemNameDictionary = {};
        const allTemplates = getTemplatesRespBody?.data?.templates ?? [];
        const _organization = organizationResult && !(organizationResult instanceof Error) && organizationResult.belongs
            ? organizationResult.organizations.find((org) => org.id === currentOrganizationId) : undefined
        const fuModelLayers = _organization?.fuModels?.find((fm) => fm.source === fileUploadId)?.fuModelLayers ?? [];

        if (!_organization || !fileUploadId || !fuModelLayers || !allTemplates || !Array.isArray(fuModelLayers) || !Array.isArray(allTemplates)) {
            return
        }
        try {
            const currentOrgAndUploadModel = _organization.fuModels?.find((fm) => fm.source === fileUploadId)
            for (const fuModelLayer of fuModelLayers) {
                const { source, destination } = fuModelLayer;
                const matchingTemplate = allTemplates.find((t) => t.objectId === currentOrgAndUploadModel?.destination);
                const matchingLayer = matchingTemplate?.layers?.find((l: any) => l?.name === destination);
                if (matchingLayer) {
                    const systemLayerName = matchingLayer?.systemLayerName;
                    if (typeof source === 'string' && typeof systemLayerName === 'string') {
                        if (systemLayerName === 'AddressPoint') {
                            setSourceDataAPLayerName(source);
                        } else if (systemLayerName === 'RoadCenterline') {
                            setSourceDataRCLLayerName(source);
                        }
                    }

                    if (source && destination) {

                        let newFieldDictionary: any = {}
                        for (const field of fuModelLayer.fuModelLayerFields) {
                            newFieldDictionary[field.source] = field.destination;
                        }

                        newSystemNameDictionary[source] = {
                            layerName: systemLayerName,
                            fields: newFieldDictionary
                        }
                    }
                }
            }
        } catch (e) {
            console.warn('Error setting source layer names:', e)
        }

        setSystemNameDictionary(newSystemNameDictionary)
    }, [organizationResult, currentOrganizationId, fileUploadId, getTemplatesRespBody])

    // property names by layer effect
    useEffect(() => {
        if (!accessToken || !currentOrganizationId || !fileUploadId || propsByLayerNameObj) {
            console.log('DEBUG not ready to fetch or already fetched')
            return
        }

        getAllLayerFeaturePropertyOrders(accessToken, currentOrganizationId, fileUploadId).then((result) => {
            console.log(`DEBUG results: ${JSON.stringify(result)}`)
            const propsByLayerNameObj: any = {}
            result.data.layers.forEach((lfpo) => {
                propsByLayerNameObj[lfpo.layerName] = { properties: lfpo.properties }
            })
            setPropsByLayerNameObj(propsByLayerNameObj)
        }).catch((err) => {
            console.warn('Error fetching feature properties by layer name:', err)
        });


    }, [accessToken, currentOrganizationId, fileUploadId, propsByLayerNameObj])

    // layerSelection useEffect, reorders the layers on the map
    useEffect(() => {
        if (mapRef && mapRef.current) {
            const getLayersBySource = (sourceId: string) => {
                const allLayers = mapRef.current?.getStyle().layers;
                return allLayers?.filter((layer: any) => layer.source === sourceId) || [];
            };

            const moveLayersOnTop = (topLayers: any[], bottomLayers: any[]) => {
                if (topLayers.length > 0) {
                    const lastTopLayerId = topLayers[0].id;
                    bottomLayers.forEach(layer => {
                        mapRef.current?.moveLayer(layer.id, lastTopLayerId);  // Move the top layers above the last road layer
                    });
                }
            }

            // map for the order the layers need to be rendered
            const orderMap: Record<string, number> = {};
            layerSelection.forEach((ls) => orderMap[ls.layerInfo.name] = ls.selectionOrder)

            const sorterSelectedLayers = [...layerSelection].filter(ls => ls.checked).sort((a, b) => {
                const orderA = orderMap[a.layerInfo.name]
                const orderB = orderMap[b.layerInfo.name]
                return orderA - orderB
            })

            // This the bottom layer and moves it under the next layer, then moves the second layer under the third, and so on.
            // Each layer is positioned above the one below it, making sure the layers stack up correctly.
            sorterSelectedLayers.forEach((sourceLayer, index) => {
                if (index === 0) {
                    return;
                } else {
                    const bottomLayers = getLayersBySource(sourceLayer.layerInfo.name)
                    const topLayers = getLayersBySource(sorterSelectedLayers[index - 1].layerInfo.name)

                    moveLayersOnTop(topLayers, bottomLayers)
                }
            });

            // Then reverse the order (starting from the top layer) and do a similar process moving each layer below the next one.
            // This makes that the order is reinforced, and fixes any mistakes where a layer might be above the wrong one.
            sorterSelectedLayers.reverse().forEach((sourceLayer, index) => {
                if (index === 0) {
                    return;
                } else {
                    const topLayers = getLayersBySource(sourceLayer.layerInfo.name)
                    const bottomLayers = getLayersBySource(sorterSelectedLayers[index - 1].layerInfo.name)

                    moveLayersOnTop(topLayers, bottomLayers)
                }
            });

            const allLayers = mapRef.current?.getStyle().layers;
            allLayers?.filter((l: any) => sorterSelectedLayers.filter(sl => sl.checked).includes(l.source)).forEach((l: any, index: number) => {
                console.log(`DEBUG ${index}. layerName: ${l.name} layerSource: ${l.source}`)
            }

            )
        }
    }, [layerSelection])

    const {
        mutate: fishboneFeatureMutate
    } = useMutation('fishbone-feature', fishboneFeature, {
        onSuccess: ({ data: responseBody }) => {
            console.log('fishbone-feature onSuccess')
            generateFishboneCollection(responseBody, true)

        },
        onError: (error, vars, ctx) => {
            console.error('OOPS\n', error, vars, ctx)
            setFishboneFeatureCollection(undefined);
            setFishboneFeatureCollectionHouseLine(undefined);
        },
        onMutate: () => {
            const mapCanvas = mapRef.current?.getCanvas();
            if (!mapCanvas) {
                return;
            }
            mapCanvas.style.cursor = 'wait'
        },
        onSettled: () => {
            const mapCanvas = mapRef.current?.getCanvas();
            if (!mapCanvas) {
                return;
            }
            mapCanvas.style.cursor = ''
        }
    })

    const getFeatureFishbone = useCallback((
        accessToken: string | null | undefined,
        organizationId: string | null,
        uploadId: string | null,
        featureToFishbone: { layerName: string; feature: Feature; } | null) => {
        if (!organizationId || !accessToken || !uploadId || !featureToFishbone) {
            console.error('Unable to fetch fishbone data.  Missing required pre-reqs.');
            return;
        }

        // fetch fishbone and let react-query handle the rest via onSuccess and onError
        fishboneFeatureMutate({
            accessToken: accessToken,
            organizationId: organizationId,
            uploadId: uploadId,
            featureId: featureToFishbone?.feature?.id || featureToFishbone?.feature?.properties?._id,
        });

    }, [fishboneFeatureMutate])


    const generateFishboneCollection = (fishboneBoneData: TFishboneInfo, paintFocusedLine = false) => {
        const { fishboneFeature, leftPoints, leftLines, rightPoints, rightLines } = fishboneBoneData
        const fishboneFeatureIsPoint = fishboneFeature.feature.geometry.type === 'Point'

        const fishbonePoints = [
            ...leftPoints,
            ...rightPoints
        ].map((x) => {
            const point: Feature<Point, GeoJsonProperties> = {
                id: uuid(),
                type: "Feature",
                properties: {
                    hno: x.houseNumber,
                    side: x.side,
                },
                geometry: x.point
            }
            return point
        })
        const fishboneLines = [
            ...leftLines,
            ...rightLines
        ].map((x) => {
            const line: Feature<LineString, GeoJsonProperties> = {
                id: uuid(),
                type: "Feature",
                properties: {},
                geometry: x
            }
            return line
        })

        let correlatedLineIdx = -1;
        if (paintFocusedLine && fishboneFeatureIsPoint) {
            correlatedLineIdx = fishboneLines.findIndex((line) => {
                const isOnLine = booleanPointOnLine(fishboneFeature.feature.geometry, line.geometry);
                return isOnLine;
            });
            if (correlatedLineIdx > -1) {
                const featureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
                    type: 'FeatureCollection',
                    features: [fishboneLines[correlatedLineIdx]]
                }
                setFishboneFeatureCollectionHouseLine(featureCollection)
            }
        } else {
            setFishboneFeatureCollectionHouseLine(undefined)
        }

        const featureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
            type: 'FeatureCollection',
            features: [
                ...fishbonePoints,
                ...(correlatedLineIdx > -1 ? fishboneLines.filter((v, idx) => idx !== correlatedLineIdx) : fishboneLines)
            ]
        }
        setFishboneFeatureCollection(featureCollection)
    }

    const generateProposedFishboneCollection = (proposedFishboneInfo: TFishboneProposalInfo, proposedGeometry: Point) => {
        if (proposedGeometry) {
            const point: Feature<Point, GeoJsonProperties> = {
                type: "Feature",
                id: "proposed-new-feature-id",
                geometry: proposedGeometry,
                properties: {}
            }

            drawControlRef.current?.add(point)
            drawControlRef.current?.changeMode('simple_select', {
                featureIds: ['proposed-new-feature-id']
            });

        }

        const fishbonePointsProposed = [
            ...proposedFishboneInfo.proposalPoints
        ].map((x) => {

            const point: Feature<Point, GeoJsonProperties> = {
                type: "Feature",
                id: uuid(),
                properties: {
                    hno: x.houseNumber,
                    side: x.side,
                },
                geometry: x.point
            }
            return point;
        });

        const fishboneLinesPropsed = [
            ...proposedFishboneInfo.proposalLines
        ].map((x) => {
            const line: Feature<LineString, GeoJsonProperties> = {
                id: uuid(),
                type: "Feature",
                properties: {},
                geometry: x
            }
            return line;
        });

        const proposeFeatureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
            type: 'FeatureCollection',
            features: [...fishbonePointsProposed, ...fishboneLinesPropsed]
        };
        setProposedFishboneFeatureCollection(proposeFeatureCollection)
    }

    // Do we want this handled on the server?
    const fishbonePointClick = useCallback(
        (e: mapboxgl.MapLayerMouseEvent) => {

            let features = e?.features?.filter((f) => f.layer.type !== 'heatmap');
            if (!Array.isArray(features) || features.length === 0) {
                return;
            }

            const clickedFishboneFeature = features[0];
            if (!clickedFishboneFeature) {
                return;
            }

            const clickedFeatureGeometry = clickedFishboneFeature.geometry as Point

            const newFishbonePointsProposed: Feature<Point, GeoJsonProperties> = {
                type: "Feature",
                properties: clickedFishboneFeature.properties,
                geometry: clickedFeatureGeometry
            }

            const newFishboneLineProposed: Feature<LineString, GeoJsonProperties> = {
                type: "Feature",
                properties: {},
                geometry: {
                    type: "LineString",
                    coordinates: [clickedFeatureGeometry.coordinates, (drawRefFeature?.geometry as Point).coordinates],
                },
            };

            const updatedProposeFeatureCollection: FeatureCollection<Geometry, GeoJsonProperties> = {
                type: 'FeatureCollection',
                features: [newFishbonePointsProposed, newFishboneLineProposed]
            };

            setProposedFishboneFeatureCollection(updatedProposeFeatureCollection);
        },
        [drawRefFeature?.geometry]
    );

    useEffect(() => {

        const mapInstance = mapRef.current;
        if (mapInstance && proposeFishboneFeatureCollection && systemNameDictionary) {
            mapInstance.on('click', 'fishbone-points-layer', fishbonePointClick);

            mapInstance.on('mouseenter', 'fishbone-points-layer', (e) => {
                if (mapInstance) {

                    //filter out address points
                    if (e.features && e.features?.length > 0 && e.features.some(feature => !(feature.layer.id.includes('fishbone')))) return;

                    mapInstance.getCanvas().style.cursor = 'pointer';
                    let features = e?.features?.filter((f) => f.layer.type !== 'heatmap');
                    if (!Array.isArray(features) || features.length === 0) return;

                    const hoveredFishboneFeature = features[0];
                    if (!hoveredFishboneFeature || !hoveredFishboneFeature.properties) {
                        return
                    }

                    const houseNumber = hoveredFishboneFeature.properties['hno'];

                    if (houseNumber) {
                        setHoverAdditions({ prefix: houseNumber })
                    }

                }
            });

            mapInstance.on('mouseleave', 'fishbone-points-layer', () => {
                if (mapInstance) {
                    mapInstance.getCanvas().style.cursor = '';
                    setHoverAdditions(undefined)
                }
            });
        }

        return () => {
            if (mapInstance) {
                mapInstance.off('click', 'fishbone-points-layer', fishbonePointClick);
            }

        };
    }, [fishbonePointClick, proposeFishboneFeatureCollection, systemNameDictionary]);

    // fishbone on selection change useEffect
    useEffect(() => {
        if (!selectedFeature) {
            return;
        }

        if (drawControlRef.current) {
            drawControlRef.current.deleteAll()
        }

        setActiveSidePanelTab(FEATURE_MANAGER_TAB)

        if ((selectedFeature.layerName === sourceDataAPLayerName
            || selectedFeature.layerName === sourceDataRCLLayerName
            || selectedFeature.feature.geometry.type === 'Point'
            || selectedFeature.feature.geometry.type === 'LineString')) {
            getFeatureFishbone(accessToken, currentOrganizationId, fileUploadId, selectedFeature)
        }

    }, [accessToken, currentOrganizationId, fileUploadId, getFeatureFishbone, sourceDataAPLayerName, sourceDataRCLLayerName, selectedFeature, proposalInfo?.geometry, proposalInfo?.featureId])

    const drawControlRef = useRef<MapboxDraw>();


    const onNewFeature = (feature: any) => {
        const sourceId = sourceDataAPLayerName || 'AddressPoints_NG911';
        const newSelectedFeature = { layerName: sourceId, feature: { ...feature.feature, id: feature.id } };
        setDrawRefFeature(null)
        setSelectedFeature(newSelectedFeature);
        getFeatureFishbone(accessToken, currentOrganizationId, fileUploadId, newSelectedFeature);
        drawControlRef?.current?.deleteAll();
        (mapRef.current?.getSource(sourceId) as any).reload();
    }

    const onUpateFeature = async (fetchedFeature: any) => {
        const newSelectedFeature = { layerName: fetchedFeature.data?.layerName, feature: { ...fetchedFeature.data?.feature, id: fetchedFeature.data?.id } }
        setDrawRefFeature(null)
        setSelectedFeature(newSelectedFeature)
        getFeatureFishbone(accessToken, currentOrganizationId, fileUploadId, newSelectedFeature)
        setProposedFishboneFeatureCollection(undefined);
        (mapRef.current?.getSource(fetchedFeature.data?.layerName) as any).reload()
        drawControlRef.current?.deleteAll()
        drawControlRef.current?.changeMode('simple_select')
    }

    const onFeatureProposal = (response: TFishboneProposeResponse) => {
        const { fishboneInfo, fishboneProposalInfo, featureProposal } = response

        if (fishboneInfo) {
            generateFishboneCollection(fishboneInfo, true)
        }

        if (fishboneProposalInfo) {
            generateProposedFishboneCollection(fishboneProposalInfo, featureProposal.geometry as Point)
        }

        (mapRef.current?.getSource(featureProposal.featureLayer) as any).reload();
    }

    const onStartAddFeature = () => {
        drawControlRef.current?.deleteAll()
        drawControlRef.current?.changeMode('draw_point')
    }

    const onFeatureProposalCancel = () => {
        drawControlRef.current?.deleteAll()
        setDrawRefFeature(null)
        setProposedFishboneFeatureCollection(undefined)
        const sourceId = sourceDataAPLayerName || 'AddressPoints_NG911';
        (mapRef.current?.getSource(sourceId) as any).reload();
        if (selectedFeature) {
            getFeatureFishbone(accessToken, currentOrganizationId, fileUploadId, selectedFeature)
        }
    }

    const onDeleteFeature = () => {
        setSelectedFeature(undefined)
        setProposalInfo(undefined)
        setFishboneFeatureCollectionHouseLine(undefined)
        openSuccessSnackbar('Feature deleted successfully')
        const sourceId = sourceDataAPLayerName || 'AddressPoints_NG911';
        (mapRef.current?.getSource(sourceId) as any).reload();
        setActiveSidePanelTab(SELECT_TAB_ID)
    }

    const drawRefUpdate = useCallback(async (e: any) => {
        for (const f of e.features) {
            setDrawRefFeature({ geometry: f.geometry, featureId: f.id })
        }
    }, []);


    const onDelete = useCallback((e: any) => {
    }, []);


    if (!organizationResult) {
        return <div>Loading...</div>
    }
    if (organizationResult instanceof Error || !organizationResult.belongs) {
        return <div>Loading...</div>
    }

    const currentOrganization = organizationResult.organizations.find((org) => org.id === currentOrganizationId)

    if (!currentOrganization) {
        // show an error page
        return <div>Loading...</div>
    }

    const orgLayers = currentOrganization.fileUploads.find((f) => f.uploadId === fileUploadId)?.layers ?? []

    const collectionName = (orgAuth0Name: string, fileUploadId: string) => `org${orgAuth0Name}Gdb_${fileUploadId}`

    const layerUrl = (collectionName: string, layerName: string) => `https://mongeo.azurewebsites.net/tiles/${collectionName}/layer/${layerName}/{z}/{x}/{y}`

    const layerUrlFromName = (layerName: string) => layerUrl(collectionName(currentOrganization.auth0Name, fileUploadId), layerName)

    const layerUrlClassified = (collectionName: string, auth0Name: string, layerName: string) => `https://mongeo.azurewebsites.net/classified/${collectionName}/${auth0Name}/layer/${layerName}/{z}/{x}/{y}`

    const layerUrlClassifiedFromName = (layerName: string) => layerUrlClassified(collectionName(currentOrganization.auth0Name, fileUploadId), currentOrganization.auth0Name, layerName)


    const updateSelectedLayer = (updatedLayer: TLayerSelection) => {

        //remove the previous version of the layer
        const modifiedIndex = layerSelection.findIndex(layer => layer.layerInfo.name === updatedLayer.layerInfo.name)
        layerSelection[modifiedIndex] = updatedLayer
        //add the new version of the layer
        setLayerSelection(layerSelection.map(ls => {
            return ls.layerInfo.name === updatedLayer.layerInfo.name ? updatedLayer : ls
        }))

        console.log('Should this next line run?')
        setSelectedFeature(undefined)
    }

    // TODO: cleanup clicked feature types
    const handleFeatureClickedFromSearchTab = (feature: any) => {

        setLayerSelection(layerSelection.map(ls => {
            return feature.layerName === ls.layerInfo.name ? new TLayerSelection(ls.layerInfo, true, ls.rgbColor, ls.selectionOrder) : ls
        }))
        console.log(`feature: ${JSON.stringify(feature)}`)
        setSelectedFeature({ layerName: feature.layerName, feature: { ...feature.feature, id: feature.id } })
        focusFeatureByGeometry(feature.feature.geometry, 18)
    }

    const tabs: TabItem[] = [
        {
            id: SELECT_TAB_ID,
            disabled: false,
            tooltip: 'Select Layer',
            icon: <LayersIcon />,
            content: <SelectLayerTab apLayerName={sourceDataAPLayerName} rclLayerName={sourceDataRCLLayerName} layerSelection={layerSelection} orgLayers={orgLayers} updateSelectedLayer={updateSelectedLayer} />
        },
        {
            id: SEARCH_TAB_ID,
            tooltip: 'Search',
            disabled: false,
            icon: <SearchIcon />,
            content: <SearchTab accessToken={accessToken!} organizationId={currentOrganizationId!} fileUploadId={fileUploadId} templateStringsMap={templateStringMap} handleFeatureClickedFromSearchTab={handleFeatureClickedFromSearchTab} />
        },
        {
            id: FEATURE_MANAGER_TAB,
            tooltip: '',
            disabled: false,
            icon: <IconButton ><DashboardIcon /></IconButton>,
            content: <FeatureManager map={mapRef.current?.getMap()}
                drawControlRef={drawControlRef}
                accessToken={accessToken}
                organizationId={currentOrganizationId}
                fileUploadId={fileUploadId}
                selectedFeatureId={selectedFeature?.feature.id as string}
                proposedMapFeature={drawRefFeature}
                fishbonePointLayerName='fishbone-points-layer'
                propsByLayerNameObj={propsByLayerNameObj}
                systemNameDictionary={systemNameDictionary}
                templateStringMap={templateStringMap}

                focusFeatureByGeometry={focusFeatureByGeometry}
                selectFeaturesById={makeFeatureMoveable}
                onStartAddFeature={onStartAddFeature}
                onSaveNewFeature={onNewFeature}
                onUpdateFeature={onUpateFeature}
                onDeleteFeature={onDeleteFeature}
                onFeatureProposal={onFeatureProposal}
                onFeatureProposalCancel={onFeatureProposalCancel} />,

        }

    ];

    // get the color of the source data's AP layer or fallbck to the first point layer and apply to the fishbone
    const fishboneLayerColor = layerSelection?.find(x => x && (x.layerInfo.name === sourceDataAPLayerName || x.layerInfo.type === 'Point'))?.rgb1()
    // console.log(`fishboneLineColor: ${fishboneLayerColor}\n${JSON.stringify({ sourceDataAPLayerName, layerSelection })}`)

    return (
        <Shell>
            <SidePanelTabs tabs={tabs} activeTab={activeSidePanelTab} setActiveTab={(tabId) => {
                setActiveSidePanelTab(tabId)
            }} />

            <ReactMap
                ref={mapRef}
                style={{ width: '100vw', height: 'calc(100vh - 64px)' }}
                mapStyle={mapStyle}
                onMove={e => setViewState(e.viewState)}
                onLoad={() => {
                    setMapRefLoaded(true)
                }}
                onStyleData={(mbe) => {
                    const _map = mbe.target;
                    const _layers = _map.getStyle().layers;
                    for (const l of _layers) {
                        if (l.type === 'symbol') {
                            setFirstSymbolLayerId(l.id)
                            break
                        }
                    }
                }}
                onSourceData={onSourceData}
                onClick={onClick}
                {...viewState}
            >
                <Card sx={{ position: 'absolute', top: 8, right: 8 }}>
                    <Select label="Map Style" value={mapStyle} onChange={(e) => setMapStyle(e.target.value)}>
                        {possibleMapStyles.map((style) => (
                            <MenuItem key={style.mapStyle} value={style.mapStyle}>{style.displayName}</MenuItem>
                        ))}
                    </Select>
                </Card>
                <Layers
                    layers={orgLayers}
                    roadLabels={currentOrganization.roadLabels}
                    roadZoom={currentOrganization.roadZoom}
                    selectedLayers={layerSelection}
                    layerUrlFromName={layerUrlFromName}
                    layerUrlClassifiedFromName={layerUrlClassifiedFromName}
                    templateStringMap={templateStringMap}
                    firstSymbolLayerId={firstSymbolLayerId}
                    hoverAdditions={hoverAdditions}
                />

                <DrawControl
                    ref={drawControlRef}
                    displayControlsDefault={false}
                    controls={{}}
                    onCreate={drawRefUpdate}
                    onUpdate={drawRefUpdate}
                    onDelete={onDelete}
                />
                {fishboneFeatureCollection && <>
                    <Source id="fishbone-source" type="geojson" data={fishboneFeatureCollection}>
                        {proposeFishboneFeatureCollection && <Layer
                            id="fishbone-points-layer"
                            type="circle"
                            paint={{
                                'circle-color': fishboneLayerColor ?? 'purple',
                                'circle-radius': 4,
                            }}
                            filter={['==', '$type', 'Point']}
                        />}
                        <Layer
                            id="fishbone-lines-layer"
                            type="line"
                            paint={{
                                'line-color': fishboneLayerColor ?? 'purple',
                                'line-width': 4
                            }}
                            filter={['==', '$type', 'LineString']}
                        />
                    </Source>

                </>}
                {fishboneFeatureCollectionHouseLine && <>
                    <Source id="fishbone-source-house-line" type="geojson" data={fishboneFeatureCollectionHouseLine}>
                        <Layer
                            id="fishbone-source-house-line-layer"
                            type="line"
                            paint={{
                                'line-color': 'cyan',
                                'line-width': 4
                            }}
                            filter={['==', '$type', 'LineString']}
                        />
                    </Source>
                </>
                }
                {proposeFishboneFeatureCollection && <>
                    <Source id="fishbone-propose-source" type="geojson" data={proposeFishboneFeatureCollection}>
                        <Layer
                            id="propose-fishbone-points-layer"
                            type="circle"
                            source="my-data"
                            paint={{
                                'circle-color': 'yellow',
                                'circle-radius': 4,
                            }}
                            filter={['==', '$type', 'Point']}

                        />
                        <Layer
                            id="propose-fishbone-lines-layer"
                            type="line"
                            source="my-data"
                            paint={{
                                'line-color': 'yellow',
                                'line-width': 4,
                            }}
                            filter={['==', '$type', 'LineString']}

                        />

                    </Source>

                </>}
            </ReactMap>
            <Snackbar open={openSnackbar} autoHideDuration={10000} onClose={handleCloseSnackbar}>
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarAlertSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarAlertContent}
                </Alert>
            </Snackbar>
        </Shell >
    )
}

export default MapPage;

