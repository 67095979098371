import { Grid, Tooltip, Button, TableContainer, Table, TableBody, TableRow, TableCell, TextField } from "@mui/material";
import { Feature, Geometry, Point } from 'geojson';
import mapboxgl, { Map as MapBox } from "mapbox-gl";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import React, { MutableRefObject, useCallback, useEffect, useState } from "react";
import { useAppContext } from "../App";
import { useMutation } from "react-query";
import { deleteFeature, includePointToRoadCenterLineFishbone, proposeFeature, saveFeature, TFishboneProposeResponse, TProposeFeatureResponseBody, updateFeature } from "../api/editController";
import { sortProperties, TUpdateFeatureTabFormStoreValues } from "./map/tabs/UpdateFeatureTab";
import { TProposalInfo, TSystemNameDictionary } from "../pages/Map";
import { findAndReplaceFieldAutoPopulateTemplateString, findAndReplaceLayerTemplateString, TSourceFeatureData } from "./map/templateString";
import MDEditor from "@uiw/react-md-editor";
import { TConfirmDialogProps, ConfirmDialog } from "./ConfirmDialog";
import { getFeatureById } from "../api/featureController";


type FeatureManageProps = {
    map: MapBox | undefined
    drawControlRef: MutableRefObject<MapboxDraw | undefined>
    accessToken: string | undefined,
    organizationId: string | null,
    fileUploadId: string,
    selectedFeatureId: string | null,
    proposedMapFeature: { geometry: Geometry; featureId: string; } | null
    propsByLayerNameObj?: any
    systemNameDictionary: TSystemNameDictionary | undefined
    fishbonePointLayerName?: string
    templateStringMap: Map<string, string>

    selectFeaturesById: (featureId: string[]) => void
    focusFeatureByGeometry: (geomtry: Geometry, zoom?: number) => void
    onStartAddFeature: () => void
    onSaveNewFeature: (feature: any) => void
    onUpdateFeature: (feature: any) => void
    onFeatureProposal: (featureProposeResponse: TFishboneProposeResponse) => void
    onFeatureProposalCancel: () => void
    onDeleteFeature: () => void
}

const FormState = {
    EDITING: "editing",
    ADDING: "adding",
    VIEWING: "view"
};

const FeatureManager: React.FC<FeatureManageProps> =
    ({ map, organizationId, fileUploadId, selectedFeatureId, proposedMapFeature, propsByLayerNameObj, systemNameDictionary, fishbonePointLayerName, templateStringMap,
        focusFeatureByGeometry, selectFeaturesById, onStartAddFeature, onSaveNewFeature, onUpdateFeature, onFeatureProposal, onFeatureProposalCancel, onDeleteFeature }) => {
        const [formState, setFormState] = useState(FormState.VIEWING);
        const [selectedFeature, setSelectedFeature] = useState<{ layerName: string, feature: Feature }>()
        const [proposalInfo, setProposalInfo] = useState<TProposalInfo | undefined>(undefined)
        // hardcoded to AP right now probably make this selectable somewhere in the UI for Add purposes
        let workingLayer = ''
        if (systemNameDictionary) {
            workingLayer = Object.entries(systemNameDictionary).find(([_, value]) => value.layerName === "AddressPoint")?.[0] ?? ''
        }

        const [changedFields, setChangedFields] = useState<Set<string>>(new Set())
        const [formValues, setFormValues] = useState<TUpdateFeatureTabFormStoreValues>({ geometry: null, properties: [], })
        const [deleteConfirmState, setDeleteConfirmState] = useState<Omit<TConfirmDialogProps, 'onClose' | 'onConfirm'>>({
            isOpen: false,
            title: 'Delete',
            content: 'Are you sure you want to delete this feature?',
            cancelBtnDisabled: false,
            cancelBtnContent: 'Cancel',
            confirmBtnDisabled: false,
            confirmBtnContent: 'Delete',
        })
        const { accessToken } = useAppContext();

        const fetchAndSetFeatureById = useCallback(async (featureId: string) => {
            if (!accessToken || !organizationId || !fileUploadId || !featureId) {
                console.log("Not enough info to fetch feature")
                return;
            }
            let response = await getFeatureById(accessToken, organizationId, fileUploadId, featureId);
            const feature = { ...response.data?.feature, id: response.data?.id }
            setSelectedFeature({ layerName: response.data?.layerName, feature })
            return response
        }, [accessToken, fileUploadId, organizationId])

        // TODO: HIT THE SERVER INSTEAD
        const fishbonePointClick = useCallback(
            (e: mapboxgl.MapLayerMouseEvent) => {
                if (!proposalInfo) {
                    return;
                }

                console.log("fishbonePointClick: ");
                console.log(proposalInfo);

                let features = e?.features?.filter((f) => f.layer.type !== 'heatmap');
                if (!Array.isArray(features) || features.length === 0) {
                    return;
                }

                const clickedFishboneFeature = features[0];
                if (!clickedFishboneFeature) {
                    return;
                }

                if (systemNameDictionary) {
                    const layerMap = Object.values(systemNameDictionary).find(
                        (item: { layerName: string }) => item.layerName === 'AddressPoint'
                    )

                    if (!layerMap) {
                        return;
                    }

                    const addNumberProperty = Object.entries(layerMap.fields).find((item) => (item[1] === 'Add_Number' || item[1] === 'HNO'));
                    if (!addNumberProperty) {
                        return;
                    }

                    const modelAddNumberProperty = addNumberProperty[0];

                    if (!clickedFishboneFeature.properties) {
                        return
                    }

                    const newHouseNumber = clickedFishboneFeature.properties['hno'];
                    console.log(`newHouseNumber : ${newHouseNumber}`);

                    if (proposalInfo) {
                        setProposalInfo(prev => {
                            if (!prev) {
                                return prev;
                            }

                            return {
                                ...prev,
                                ...({ properties: proposalInfo.properties.map((prop) => prop.name === modelAddNumberProperty ? { name: prop.name, value: newHouseNumber } : prop) }),
                                ...({ changedProperties: { ...proposalInfo.changedProperties, [modelAddNumberProperty]: newHouseNumber } })
                            }
                        });
                    }
                }
            },
            [proposalInfo, systemNameDictionary]
        );

        const setSelectedFeatureFormValues = useCallback(() => {
            if (!selectedFeature || !selectedFeature.feature || !selectedFeature.feature.properties) {
                setFormValues({ geometry: null, properties: [], })
                return;
            }

            const properties = Object.entries(selectedFeature.feature.properties).map(([key, value], i) => ({ name: key, value: value }));

            // need to reset this if the feature changed
            setChangedFields(new Set());

            const sortedProperties = sortProperties(propsByLayerNameObj, selectedFeature.layerName, properties);
            setFormValues({
                geometry: selectedFeature.feature.geometry,
                properties: sortedProperties,
                id: selectedFeature.feature.id,
            });
        }, [propsByLayerNameObj, selectedFeature])

        const {
            mutate: updateFeatureMutation,
            isLoading: isUpdatingFeature
        } = useMutation('update-feature', updateFeature, {
            onSuccess: async ({ data: responseBody }) => {
                var { featureId } = responseBody;
                fetchAndSetFeatureById(featureId).then((feature) => { onUpdateFeature(feature) })
            },
            onError: (error, vars, ctx) => {
                console.error("Update feature failed", error)
            },
            onMutate: () => {
                const mapCanvas = map?.getCanvas();
                if (!mapCanvas) {
                    return;
                }
                mapCanvas.style.cursor = 'wait'
            },
            onSettled: () => {
                const mapCanvas = map?.getCanvas();
                if (!mapCanvas) {
                    return;
                }
                mapCanvas.style.cursor = ''
            }
        })

        const {
            mutate: deleteFeatureMutation,
            isLoading: isDeleting
        } = useMutation('delete-feature', deleteFeature, {
            onSuccess: ({ data: responseBody }) => {
                // reset some state
                _onCloseDeleteConfirmDialog();

                // reload the map
                (map?.getSource(workingLayer) as any).reload();
                onDeleteFeature();
            },
            onError: (error, vars, ctx) => {
                console.error("Feature delete failed", error)
            },
            onMutate: () => {
                const mapCanvas = map?.getCanvas();
                if (!mapCanvas) {
                    return;
                }
                mapCanvas.style.cursor = 'wait'
            },
            onSettled: () => {
                const mapCanvas = map?.getCanvas();
                if (!mapCanvas) {
                    return;
                }
                mapCanvas.style.cursor = ''
            }
        })

        const {
            mutate: saveFeatureMutation,
            isLoading: isSavingFeature
        } = useMutation('save-feature', saveFeature, {
            onSuccess: ({ data: responseBody }) => {
                onSaveNewFeature(responseBody)
            },
            onError: (error, vars, ctx) => {
                console.error("Save feature failed", error)
            },
            onMutate: () => {
                const mapCanvas = map?.getCanvas();
                if (!mapCanvas) {
                    return;
                }
                mapCanvas.style.cursor = 'wait'
            },
            onSettled: () => {
                const mapCanvas = map?.getCanvas();
                if (!mapCanvas) {
                    return;
                }
                mapCanvas.style.cursor = ''
            }
        })

        const {
            mutate: proposeFeatureMutation,
            isLoading: isProposing
        } = useMutation('proposeFeature', proposeFeature, {
            onSuccess: ({ data: responseBody }) => {
                generateAndSetProposalInfo(responseBody.featureProposal, responseBody.featureProposal.featureId, responseBody.featureProposal.geometry);
                onFeatureProposal(responseBody)
            },
            onError: (error, vars, ctx) => {

            },
            onMutate: () => {
                const mapCanvas = map?.getCanvas();
                if (!mapCanvas) {
                    return;
                }
                mapCanvas.style.cursor = 'wait'
            },
            onSettled: () => {
                const mapCanvas = map?.getCanvas();
                if (!mapCanvas) {
                    return;
                }
                mapCanvas.style.cursor = ''
            }
        })

        const {
            mutate: includePointToRoadCenterLineFishboneMutation
        } = useMutation('include-fishbone-feature', includePointToRoadCenterLineFishbone, {
            onSuccess: ({ data: responseBody }) => {
                const { featureProposal } = responseBody
                generateAndSetProposalInfo(featureProposal, featureProposal.featureId, featureProposal.geometry)
                onFeatureProposal(responseBody)
            },
            onError: (error, vars, ctx) => {
                console.error('Including proposal failed', error)

            },
            onMutate: () => {
                const mapCanvas = map?.getCanvas();
                if (!mapCanvas) {
                    return;
                }
                mapCanvas.style.cursor = 'wait'
            },
            onSettled: () => {
                const mapCanvas = map?.getCanvas();
                if (!mapCanvas) {
                    return;
                }
                mapCanvas.style.cursor = ''
            }
        })

        // useEffect for creating fishbonePointClicks
        useEffect(() => {
            if (map && proposalInfo && systemNameDictionary && fishbonePointLayerName) {
                map.on('click', fishbonePointLayerName, fishbonePointClick);
            }

            return () => {
                if (map && fishbonePointLayerName) {
                    map.off('click', fishbonePointLayerName, fishbonePointClick);
                }
            };
        }, [fishbonePointLayerName, fishbonePointClick, map, proposalInfo, systemNameDictionary]);


        // proposeFeatureId useEffect
        // calls to the server for proposalInfo
        useEffect(() => {
            if (!map || !proposedMapFeature?.featureId) {
                return;
            }

            proposeFeatureMutation({ accessToken, organizationId: organizationId, uploadId: fileUploadId, geometry: proposedMapFeature.geometry, featureId: proposedMapFeature?.featureId })
        }, [accessToken, fileUploadId, map, organizationId, proposeFeatureMutation, proposedMapFeature?.featureId, proposedMapFeature?.geometry]);

        // feature useEffect
        // sets the feature data to the form
        useEffect(() => {
            if (
                selectedFeature &&
                (
                    !selectedFeature?.feature.id ||
                    selectedFeature.feature.geometry.type !== 'LineString' ||
                    proposalInfo?.geometry.type !== 'Point'
                )
            ) {
                setProposalInfo(undefined);
                setFormState(FormState.VIEWING);
                setSelectedFeatureFormValues();
            }
            // this should NOT rerender when the proposalInfo changes
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedFeature, setSelectedFeatureFormValues]);

        // useEffect for handleing RCL swithcing on click
        useEffect(() => {
            if (
                accessToken &&
                organizationId &&
                fileUploadId &&
                selectedFeature?.feature.id &&
                selectedFeature.feature.geometry.type === 'LineString' &&
                proposalInfo?.geometry.type === 'Point'
            ) {
                includePointToRoadCenterLineFishboneMutation({
                    accessToken,
                    organizationId,
                    uploadId: fileUploadId,
                    point: proposalInfo.geometry as Point,
                    proposedFeatureId: proposalInfo.featureId,
                    rclFeatureId: selectedFeature.feature.id as string,
                });
            }
        }, [accessToken, fileUploadId, includePointToRoadCenterLineFishboneMutation, organizationId, proposalInfo?.featureId, proposalInfo?.geometry, selectedFeature?.feature.geometry.type, selectedFeature?.feature.id]);

        // featureId useEffect
        // fetches then sets the feature
        useEffect(() => {
            if (!selectedFeatureId) {
                return
            }

            fetchAndSetFeatureById(selectedFeatureId)
        }, [selectedFeatureId, fetchAndSetFeatureById])

        // proposalInfo useEffect
        // proposalInfo will override everything the formValues property
        // sets the "Latitude, Longitude, and DateUpdate on the form"
        useEffect(() => {
            if (proposalInfo && systemNameDictionary) {
                let proposedProps: { name: string, value: string }[] = [];

                const fields = systemNameDictionary[proposalInfo.layerName].fields
                const propertiesToChange = ["Latitude", "Longitude", "DateUpdate"].map(systemName => Object.keys(fields).find(sourceField => fields[sourceField] === systemName)).filter(source => source !== undefined)

                proposalInfo.properties.forEach((prop: { [key: string]: any; }) => {
                    proposedProps.push({
                        name: prop.name,
                        value: prop.value
                    })
                })

                propertiesToChange.forEach(propertyNameToChange => {
                    const matchingItem = proposalInfo.properties.find((updatedItem: { [key: string]: any; }) => updatedItem.name === propertyNameToChange);

                    if (!matchingItem) {
                        return;
                    }

                    const existingIndex = proposedProps.findIndex(
                        item => item.name === matchingItem.name
                    );

                    if (existingIndex !== -1) {
                        proposedProps.splice(existingIndex, 1);
                    }

                    proposedProps.push({ name: matchingItem.name, value: matchingItem.value });
                })

                setFormValues(prev => {

                    setChangedFields(prevSet => {
                        const updatedSet = new Set(prevSet);
                        proposedProps.forEach((property: { name: string, value: string }) => {
                            const changedProperty = prev.properties.find((curValue: {
                                value: unknown; name: string
                            }) => {
                                return curValue.name === property.name && curValue.value !== property.value;
                            })

                            if (changedProperty) {
                                updatedSet.add(changedProperty.name)
                            }

                        })
                        if (proposalInfo.changedProperties) {
                            Object.entries(proposalInfo.changedProperties).forEach(([key, value]) => {
                                updatedSet.add(key)
                            });
                        }

                        return updatedSet;
                    })

                    return {
                        properties: proposedProps,
                        geometry: proposalInfo.geometry,
                        id: prev.id,
                    }
                });
            }

        }, [proposalInfo, workingLayer, systemNameDictionary])

        const _onCloseDeleteConfirmDialog = () => {
            setDeleteConfirmState({
                ...deleteConfirmState,
                content: 'Are you sure you want to delete this feature?',
                isOpen: false,
                cancelBtnDisabled: false,
                confirmBtnDisabled: false,
            })
        };
        const _onConfirmDeleteConfirmDialog = async () => {
            const featureId = selectedFeature?.feature?.id as string
            if (!featureId) {
                throw new Error('Unable to delete feature without id!')
            }

            // disable the buttons
            setDeleteConfirmState({
                ...deleteConfirmState,
                cancelBtnDisabled: true,
                confirmBtnDisabled: true,
            })

            if (!accessToken || !organizationId || !fileUploadId) {
                console.error("Does not have data to call updateFeature");
                return;
            }
            // hit server to delete feature
            deleteFeatureMutation({ accessToken, organizationId: organizationId, uploadId: fileUploadId, featureId });


        };

        const onDelete = async () => {
            const featureData: TSourceFeatureData = {
                layer: selectedFeature?.layerName!,
                properties: selectedFeature?.feature?.properties!
            }
            const featureMarkdown = findAndReplaceLayerTemplateString(templateStringMap, featureData)
            setDeleteConfirmState({
                ...deleteConfirmState,
                isOpen: true,
                ...(featureMarkdown !== '' ? {
                    content: <>
                        <p>Are you sure you want to delete this feature?</p>
                        <hr />
                        <MDEditor.Markdown source={featureMarkdown} style={{ whiteSpace: 'pre-wrap' }} />
                    </>
                } : {})
            })
        }

        const onEditSave = useCallback(() => {
            if (formState === FormState.EDITING) {
                const feature: Feature = {
                    type: "Feature",
                    geometry: formValues.geometry,
                    properties: formValues.properties.reduce((a: any, v: any) => ({ ...a, [v.name]: v.value }), {})
                }

                if (!accessToken || !organizationId || !fileUploadId || !proposalInfo?.featureId) {
                    console.error("Does not have data to call updateFeature");
                    return;
                }

                updateFeatureMutation({
                    accessToken: accessToken,
                    organizationId: organizationId,
                    uploadId: fileUploadId,
                    featureId: proposalInfo?.featureId,
                    feature: feature
                })
                setChangedFields(new Set());

            } else {
                console.error("Tried to Edit save, with form in the wrong state")
            }

        }, [accessToken, fileUploadId, formState, formValues.geometry, formValues.properties, organizationId, proposalInfo?.featureId, updateFeatureMutation])

        const onAddingSave = useCallback(() => {
            if (formState === FormState.ADDING) {

                const feature: Feature = {
                    type: "Feature",
                    geometry: formValues.geometry,
                    properties: formValues.properties.reduce((a: any, v: any) => ({ ...a, [v.name]: v.value }), {})
                }
                saveFeatureMutation({
                    accessToken: accessToken,
                    organizationId: organizationId,
                    uploadId: fileUploadId,
                    layerName: workingLayer,
                    feature: feature
                })
                setChangedFields(new Set());
            } else {
                console.error("Tried to Edit save, with form in the wrong state")
            }
            setChangedFields(new Set());
        }, [accessToken, fileUploadId, formState, formValues.geometry, formValues.properties, organizationId, saveFeatureMutation, workingLayer])

        const onAdd = () => {
            setSelectedFeature(undefined)
            setProposalInfo(undefined)
            setFormState(FormState.ADDING)
            onStartAddFeature()
        }

        const onMove = useCallback(() => {
            setFormState(FormState.EDITING)
            selectFeaturesById([selectedFeature?.feature?.id as string])
        }, [selectFeaturesById, selectedFeature?.feature?.id])

        const onEditCancel = () => {
            setSelectedFeatureFormValues();
            setFormState(FormState.VIEWING)
            onFeatureProposalCancel();
        }

        const onAddCancel = () => {
            setFormState(FormState.VIEWING)
            onFeatureProposalCancel();
        }

        const handleFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propertyIndex: number) => {
            if (formState !== FormState.ADDING && formState !== FormState.EDITING) {
                setFormState(FormState.EDITING)
            }

            let property = formValues.properties[propertyIndex]
            setChangedFields(prev => {
                let newSet = new Set(prev)
                newSet.add(property.name)
                return newSet
            })
            formValues.properties[propertyIndex].value = event.target.value
            setFormValues(formValues)
        }, [formState, formValues])

        const generateAndSetProposalInfo = (addressProposal: TProposeFeatureResponseBody, featureId: string, geometry: Geometry) => {
            console.log(`[DEBUG] Proposal Response: \n${JSON.stringify(addressProposal, null, 2)} `)
            const autoPopulatedProperties = addressProposal.autoPopulatedProperties
            const nearestAPProps = addressProposal.nearestAddressPoint.feature.properties;

            const proposedProperties = nearestAPProps && Object.entries(nearestAPProps).map(([key, value], i) => {
                return { name: key, value: value }
            })

            if (autoPopulatedProperties) {
                Object.keys(autoPopulatedProperties).forEach((autopp: any) => {
                    const pp = proposedProperties.find((pp: any) => pp.name === autopp)
                    pp.value = findAndReplaceFieldAutoPopulateTemplateString(pp.value, proposedProperties)
                });
            }

            setProposalInfo(prev => {
                if (prev == null) {
                    // Handle the case where prev is null or undefined
                    return {
                        featureId: featureId,
                        layerName: addressProposal.featureLayer,
                        geometry: geometry,
                        properties: proposedProperties,
                        changedProperties: addressProposal.changedProperties,
                        propertyToFocus: addressProposal.propertyToFocus
                    };
                }

                return {
                    ...prev,
                    ...(featureId !== prev.featureId && { featureId }),
                    ...((geometry.type !== prev.geometry.type || (prev.geometry as Point).coordinates[0] !== (geometry as Point).coordinates[0] || (prev.geometry as Point).coordinates[1] !== (geometry as Point).coordinates[1]) && { geometry }),
                    ...(proposedProperties !== prev.properties && { properties: proposedProperties }),
                    ...(addressProposal.changedProperties !== prev.changedProperties && { changedProperties: addressProposal.changedProperties })
                    , ...(addressProposal.propertyToFocus !== prev.propertyToFocus && { propertyToFocus: addressProposal.propertyToFocus })
                };
            })

        }

        const renderButtonGroup = (currentFormState: string) => {
            const actions = {
                [FormState.VIEWING]: [
                    { title: "Delete", onClick: () => onDelete(), icon: <CancelIcon />, disabled: !selectedFeature },
                    { title: "Add", onClick: () => onAdd(), icon: <ControlPointIcon />, disabled: false },
                    { title: "Move", onClick: () => onMove(), icon: <OpenWithIcon />, disabled: !selectedFeature },
                    { title: "Focus", onClick: () => focusFeatureByGeometry(formValues.geometry), icon: <LocationSearchingIcon />, disabled: !selectedFeature }
                ],
                [FormState.EDITING]: [
                    { title: "Cancel", onClick: () => onEditCancel(), icon: <CancelIcon />, disabled: isDeleting || isSavingFeature || isProposing || isUpdatingFeature },
                    { title: "Save", onClick: () => onEditSave(), icon: <SaveIcon />, disabled: isDeleting || isSavingFeature || isProposing || isUpdatingFeature },
                    { title: "Focus", onClick: () => focusFeatureByGeometry(formValues.geometry), icon: <LocationSearchingIcon />, disabled: false },
                ],
                [FormState.ADDING]: [
                    { title: "Cancel", onClick: () => onAddCancel(), icon: <CancelIcon />, disabled: isDeleting || isSavingFeature || isProposing || isUpdatingFeature },
                    { title: "Save", onClick: () => onAddingSave(), icon: <SaveIcon />, disabled: isDeleting || isSavingFeature || isProposing || isUpdatingFeature },
                    { title: "Focus", onClick: () => focusFeatureByGeometry(formValues.geometry), icon: <LocationSearchingIcon />, disabled: false },
                ],
            };

            return actions[currentFormState]?.map((action, index) => (
                <Grid item xs={12 / actions[formState].length} key={index}>
                    <Tooltip title={action.title}>
                        <Button onClick={action.onClick} disabled={action.disabled}>
                            {action.icon}
                        </Button>
                    </Tooltip>
                </Grid>
            ));
        };

        return (
            <React.Fragment>
                <>
                    <Grid container direction={'row'} alignItems={'normal'}>
                        {formState === FormState.VIEWING && renderButtonGroup(FormState.VIEWING)}
                        {formState === FormState.EDITING && renderButtonGroup(FormState.EDITING)}
                        {formState === FormState.ADDING && renderButtonGroup(FormState.ADDING)}
                    </Grid>
                    <TableContainer >
                        <Table size={'small'}>
                            <TableBody>
                                {formValues.properties?.map((_, i) => {
                                    let conditionalStyles = null
                                    if (changedFields.has(formValues.properties[i].name)) {
                                        conditionalStyles = { backgroundColor: 'rgb(202, 210, 255)', opacity: .8 }
                                    }

                                    return (
                                        <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...conditionalStyles }}>
                                            <TableCell align="right">
                                                <TextField label={formValues.properties[i].name}
                                                    InputLabelProps={{
                                                        shrink: true,  // Forces the label to stay above the input field
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    margin="normal"
                                                    sx={{ width: '100%' }} onChange={(event) => handleFieldChange(event, i)} value={formValues.properties[i].value} />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
                {/* BEGIN - DELETE FEATURE STUFF */}
                <ConfirmDialog {...deleteConfirmState} onClose={_onCloseDeleteConfirmDialog} onConfirm={_onConfirmDeleteConfirmDialog} />
                {/* END - DELETE FEATURE STUFF */}
            </React.Fragment>
        );
    }

export default FeatureManager;
