import { useAuth0, User } from '@auth0/auth0-react'
import { createContext, Fragment, useContext, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { PageLoader } from './components/page-loader'
import { ProtectedRoute } from './components/protected-route'
import useOrganization, { TOrganizationResult } from './hooks/useOrganization'
import Dashboard from './pages/Dashboard'
import LayerTable from './pages/FileFeatures'
import { FileFeaturesV2 } from './pages/FileFeaturesV2'
import MapPage from './pages/Map'
import { MapPage as PublicMapPage } from './pages/public/MapPage'
import ModelsPage from './pages/Models'
import SettingsPage from './pages/Settings'

// for the map?
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import 'react-color'

// https://mui.com/material-ui/react-css-baseline/#global-reset
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import useAccessToken from './hooks/useAccessToken'
import ErrorPage from './pages/ErrorPage'
import { DefaultTheme } from './themes/Default'



export const AppContext = createContext<{
    accessToken?: string
    user?: User
    organization?: TOrganizationResult
    currentOrganizationId?: string
    updateCurrentOrganizationId?: (currentOrganizationId: string) => void
}>({})

export const useAppContext = () => useContext(AppContext)

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    },
})

function App() {
    const location = useLocation()
    const { isLoading, user, loginWithRedirect } = useAuth0()
    const accessToken = useAccessToken()
    const organizationResult = useOrganization(accessToken)

    // Redirect to login if user is not logged in and on the landing page
    useEffect(() => {
        if (!isLoading && typeof user?.sub !== 'string' && typeof loginWithRedirect === 'function') {
            loginWithRedirect()
        }
    }, [isLoading, user, loginWithRedirect])

    useEffect(() => {
        ReactGA._gaCommandSendPageview(location.pathname, undefined)
    }, [location])


    if (isLoading || !accessToken || !organizationResult || organizationResult instanceof Error) {
        if (organizationResult instanceof Error) {
            console.log(`Error: ${JSON.stringify(organizationResult.message, null, 2)}`)
            return <ErrorPage />
        }
        return (
            <div className="page-layout">
                <PageLoader />
            </div>
        )
    }

    return (
        <Fragment>
            <QueryClientProvider client={queryClient}>
                {!isLoading && typeof user?.sub === 'string' && (
                    <>
                        <CssBaseline />
                        <AppContext.Provider
                            value={{
                                accessToken: accessToken,
                                user: user,
                                organization: organizationResult,
                                // TODO: Consider adding org dictated by query param here?
                            }}
                        >
                            <ThemeProvider theme={DefaultTheme}>
                                <Switch>
                                    {/* TODO: Should we be putting the organizationId in the url parameters and not the query parameters? */}
                                    <ProtectedRoute path="/dashboard" component={Dashboard} />
                                    <ProtectedRoute path="/models/:fileUploadId" component={ModelsPage} />
                                    <ProtectedRoute path="/layerTable/:fileUploadId" component={LayerTable} />
                                    <ProtectedRoute path="/file/:fileUploadId" component={FileFeaturesV2} />
                                    <ProtectedRoute path="/map/:fileUploadId" component={MapPage} />
                                    <ProtectedRoute path="/settings" component={SettingsPage} />
                                    <Route path="/public/map/:fileUploadId" component={PublicMapPage} />
                                    <Redirect from="*" to="/dashboard" />
                                </Switch>
                            </ThemeProvider>
                        </AppContext.Provider>
                    </>
                )}
            </QueryClientProvider>
        </Fragment>
    )
}

export default App
